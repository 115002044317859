import React, {useEffect, useState} from 'react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import axios from "../utils/axios";

export default function AlertBar(props) {
    const [close, setClose] = useState(false);
    const { _id, message, alertStyle } = props.item;

    const toggleOpen = async () => {
        setClose(!close);
        await markAsReadAlert(_id);
    };

    const markAsReadAlert = async (id) => {
        try {
            await axios.post(`/api/alerts/${id}`);
        } catch (err) {
            console.error(err);
        }
    }

    const divStyle = {
        display: close ? 'none' : 'block',
        width: "100%",
        padding: "0px 0px 20px 0px"
    };
    return !close && (
        <div style={divStyle}>
            <div style={{position: "relative"}}>
            <span style={{position: "absolute", top: "2px", right: "0px", lineHeight: "2em", padding: "10px 16px", fontSize: "0.875rem", cursor: "pointer"}} onClick={toggleOpen}><CloseIcon /></span>
            <Alert icon={<CheckIcon fontSize="inherit" />} severity={alertStyle}>
                {message}
            </Alert>
            </div>
        </div>
    );
}